"use strict";
const agent = require("./agent");

module.exports = {

	list: (payload) => {
		return agent
			.get()
			.get("/api/listing/category-map", { params: payload })
			.then(response => response.data);
	},

	create: (payload) => {
		return agent
			.get()
			.post("/api/listing/category-map", payload)
			.then(response => response.data);
	},

	update: (id, payload) => {
		return agent
			.get()
			.patch("/api/listing/category-map/" + id, payload)
			.then(response => response.data);
	},

	remove: (id) => {
		return agent
			.get()
			.delete("/api/listing/category-map/" + id)
			.then(response => response.data);
	},

	removeAttribute: (channelCategoryId, attributeName) => {
		return agent
			.get()
			.delete("/api/listing/category-map/" + channelCategoryId + "/attribute/" + attributeName)
			.then(response => response.data);
	},


};
