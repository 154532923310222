const moment = require("moment");
const { parse } = require("json2csv");
const FileSaver = require("file-saver");
const agent = require("./agent");

function list(payload) {
	return agent
		.get()
		.get("/api/channel-advisor", { params: payload })
		.then((response) => response.data);
}

function userList() {
	return agent
		.get()
		.get("/api/channel-advisor/userList")
		.then((response) => response.data);
}

function profileNames() {
	return agent
		.get()
		.get("/api/channel-advisor/profileNames")
		.then((response) => response.data);
}

function get(id) {
	return agent
		.get()
		.get(`/api/channel-advisor/${id}`)
		.then((response) => response.data.data);
}

function lines(id) {
	return agent
		.get()
		.get(`/api/channel-advisor/${id}/lines`)
		.then((response) => response.data.data);
}

function requestTemplate(payload) {
	return agent
		.get()
		.post("/api/channel-advisor/request-template", payload)
		.then((response) => response.data.data);
}

function getExportStatus(payload) {
	return agent
		.get()
		.get("/api/channel-advisor/export-status", { params: payload })
		.then((response) => response.data);
}

function uploadListing(payload) {
	return agent
		.get()
		.post("/api/channel-advisor/listing/upload", payload)
		.then((response) => response.data.data);
}

function deleteUploadTemplate(id) {
	return agent
		.get()
		.delete(`/api/channel-advisor/listing/upload/${id}`)
		.then((response) => response.data);
}

function deleteTemplate(id) {
	return agent
		.get()
		.delete(`/api/channel-advisor/${id}`)
		.then((response) => response.data);
}

function exportExcel(type, data) {
	const fields = [
		"Product ID", "CA SKU", "Auction Code", "Title",
		"Remark", "Status", "Enter Date", "Last Update"
	];
	const csv = parse(data, { fields });
	const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
	const filename = `Channel-Adviser-Upload-Progress(${type}) ${moment().shortStandard()}.csv`;
	return FileSaver.saveAs(blob, filename);
}

module.exports = {
	get,
	lines,
	list,
	uploadListing,
	requestTemplate,
	getExportStatus,
	deleteUploadTemplate,
	deleteTemplate,
	exportExcel,
	userList,
	profileNames
};
