const FileSaver = require("file-saver");
const moment = require("moment");

const agent = require("./agent");

module.exports = {
	listTemplate: async function listTemplate(payload) {
		const axios = agent.get();
		const response = await axios.get(
			"/api/video/templates",
			{ params: payload }
		);
		return response.data;
	},

	downloadTemplate: async function downloadTemplate(payload) {
		const axios = agent.get();
		const response = await axios.post(
			"/api/video/templates/download",
			payload,
			{ responseType: "blob" }
		);
		if(!response.data) return null;

		const filename = `Video-${payload.place}-${moment().format("YYYY-MM-DD")}.xlsx`;
		return FileSaver.saveAs(response.data, filename);
	}
};
