"use strict";
const agent = require("./agent");

function list(payload) {
	return agent
		.get()
		.get("/api/inventory/ignore", { params: payload })
		.then(response => response.data);
}

module.exports = {
	list
};
