"use strict";
const agent = require("./agent");

function getProductDetails(payload) {
	console.log(payload)
	return agent
		.get()
		.get("/api/inventory/allocation/ProductDetails", { params: payload })
		.then(response => response.data);
}

function getProductContent(payload) {
	return agent
		.get()
		.get("/api/inventory/allocation/ProductContent", { params: payload })
		.then(response => response.data);
}

function getProductStatus(payload) {
	return agent
		.get()
		.get("/api/inventory/allocation/ProductStatus", { params: payload })
		.then(response => response.data);
}

function getListingAvailablility(payload) {
	return agent
		.get()
		.get("/api/inventory/allocation/ListingAvailablility", { params: payload })
		.then(response => response.data);
}

function getInventoryAvailablility(payload) {
	return agent
		.get()
		.get("/api/inventory/allocation/InventoryAvailablility", { params: payload })
		.then(response => response.data);
}

function getInventoryAllocation(payload) {
	return agent
		.get()
		.get("/api/inventory/allocation/InventoryAllocation", { params: payload })
		.then(response => response.data);
}

function getMondayHold(payload) {
	return agent
		.get()
		.get("/api/inventory/allocation/MondayHold", { params: payload })
		.then(response => response.data);
}	

module.exports = {
	getProductDetails,
	getProductContent,
	getProductStatus,
	getListingAvailablility,
	getInventoryAvailablility,
	getInventoryAllocation,
	getMondayHold
};
