"use strict";
const agent = require("./agent");

function createProcessQueue(payload) {
	return agent
		.get()
		.post("/api/inventory/history", { payload })
		.then(response => response.data);
}

function listProcessQueue(payload) {

	return agent
		.get()
		.get("/api/inventory/history", { params: payload })
		.then(response => response.data);
}

function remove(id) {

	return agent
		.get()
		.delete("/api/inventory/history/remove/" + id)
		.then(response => response.data);
}

module.exports = {
	createProcessQueue,
	listProcessQueue,
	remove
};
