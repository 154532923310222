const moment = require("moment");
const FileSaver = require("file-saver");
const agent = require("./agent");

async function getTags(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/tag`)
		.then((response) => response.data.data);
}

async function updateTags(id, tags) {
	return agent
		.get()
		.post(`/api/listing/${id}/tag`, { payload: { ids: tags } })
		.then((response) => response.data);
}

async function getCodes(id) {
	return agent
		.get()
		.get(`https://lms.infinitecommerce.com/api/listing/${id}/codes`)
		.then((response) => response.data.data);
}

function sales(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/sales`)
		.then((response) => response.data.data);
}

function inventory(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/inventory`)
		.then((response) => response.data.data);
}

function price(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/price`)
		.then((response) => response.data.data);
}

function breakeven(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/breakeven`)
		.then((response) => response.data?.data);
}

async function shipmentDetail(id) {
	return agent.get()
		.get(`/api/listing/${id}/shipment-detail`)
		.then((response) => response?.data);
}

async function getById(id) {
	return agent
		.get()
		.get(`/api/listing/${id}`)
		.then((response) => response.data.data);
}

async function get(payload) {
	return agent
		.get()
		.get("/api/listing", { params: payload })
		.then((response) => response.data);
}

async function create(platform, attachmentId, filename) {
	return agent
		.get()
		.post(
			"/api/listing", {
				platform,
				attachmentId,
				filename
			}
		)
		.then((response) => response.data);
}

async function reviews(id, payload) {
	return agent
		.get()
		.get(`/api/listing/${id}/reviews`, { params: payload })
		.then((response) => response.data);
}

async function shipment(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/shipment`)
		.then((response) => response.data.data);
}

async function listingStatus(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/listingStatus`)
		.then((response) => response.data.data);
}

async function downloadReviews(id) {
	const axios = agent.get();
	const csv = await axios
		.get(`/api/listing/${id}/reviews/download`, { responseType: "blob" })
		.then((response) => response.data);
	const filename = `${id}-reviews-${moment().standard()}.xlsx`;
	return FileSaver.saveAs(csv, filename);
}

async function importListing(body) {
	return agent
		.get()
		.post("/api/listing/import", body)
		.then((response) => response.data);
}

async function listImportListing(payload) {
	return agent
		.get()
		.get("/api/listing/import", { params: payload })
		.then((response) => response.data);
}

async function deleteListingImport(id) {
	return agent
		.get()
		.delete(`/api/listing/import/${id}`)
		.then((response) => response.data);
}

async function otherListing(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/related`)
		.then((response) => response.data.data);
}

async function bundleContents(id) {
	return agent
		.get()
		.get(`/api/listing/${id}/bundleContents`)
		.then((response) => response.data.data);
}

function listingDateDetails(payload) {
	return agent
		.get()
		.get("/api/listing/listing-date-details", {
			params: payload
		})
		.then((response) => response.data.data);
}

async function downloadListingDate(payload) {
	const axios = agent.get();
	const csv = await axios
		.get("/api/listing/listing-date-details/download", {
			params: payload,
			responseType: "blob"
		})
		.then((response) => response.data);
	const filename = `listing-date-details-${moment().standard()}.xlsx`;
	return FileSaver.saveAs(csv, filename);
}

function priceLog(id, payload) {
	return agent
		.get()
		.get(`/api/listing/${id}/price/log`, { params: payload })
		.then((response) => response.data);
}

function inventoryLog(id, payload) {
	return agent
		.get()
		.get(`/api/listing/${id}/inventory/log`, { params: payload })
		.then((response) => response.data);
}

function getUpdateRequest(payload) {
	return agent
		.get()
		.get("/api/listing/update-request", { params: payload })
		.then((response) => response.data);
}

function updateRequest(body) {
	return agent
		.get()
		.post("/api/listing/update-request", body)
		.then((response) => response.data);
}

async function deleteUpdateRequest(id) {
	return agent
		.get()
		.delete(`/api/listing/update-request/${id}`)
		.then((response) => response.data);
}

function getRemoveListingHistory(payload) {
	return agent
		.get()
		.get("/api/listing/remove-history", { params: payload })
		.then((response) => response.data);
}

async function getRemoveListingExcel(csv, date) {
	const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
	const filename = `Retire-Listing-History ${moment(date).format("YYYY-MM-DD")}.csv`;
	return FileSaver.saveAs(blob, filename);
}

async function getContentBlockList(payload) {
	return agent
		.get()
		.get("/api/listing/content-block-list", { params: payload })
		.then((response) => response.data);
}

async function getContentBlockListChannels(payload) {
	return agent
		.get()
		.get("/api/listing/content-block-list/channels", { params: payload })
		.then((response) => response.data);
}

module.exports = {
	get,
	create,
	getCodes,
	sales,
	shipment,
	breakeven,
	shipmentDetail,
	getById,
	reviews,
	listingStatus,
	downloadReviews,
	otherListing,
	bundleContents,
	listingDateDetails,
	downloadListingDate,
	importListing,
	listImportListing,
	deleteListingImport,
	price,
	priceLog,
	inventory,
	inventoryLog,
	getUpdateRequest,
	updateRequest,
	deleteUpdateRequest,
	getRemoveListingHistory,
	getRemoveListingExcel,
	getTags,
	updateTags,
	getContentBlockList,
	getContentBlockListChannels
};
