"use strict";
const agent = require("./agent");

async function uploadBusinessReport(payload) {
	return agent
		.get()
		.post("/api/report/business", payload)
		.then(response => response.data);
}

async function listBusinessReport(params) {
	return agent
		.get()
		.get("/api/report/business", { params })
		.then(response => response.data);
}

async function deleteBusinessReport(id) {
	return agent
		.get()
		.delete("/api/report/business/" + id)
		.then(response => response.data);
}

module.exports = {
	uploadBusinessReport,
	listBusinessReport,
	deleteBusinessReport
};
