"use strict";
const agent = require("./agent");

async function listLaunchStatus(payload) {
	return agent
		.get()
		.get("/api/product/launch-status", { params: payload })
		.then(response => response.data);
}

async function requestLaunchStatus(payload) {
	return agent
		.get()
		.post("/api/product/launch-status", { payload })
		.then(response => response.data);
}

function deleteLaunchStatusRequest(id) {
	return agent
		.get()
		.delete("/api/product/launch-status/" + id)
		.then(response => response.data);
}

module.exports = {
	listLaunchStatus,
	requestLaunchStatus,
	deleteLaunchStatusRequest
};
