"use strict";
const agent = require("./agent");

async function login(id, password) {
	const axios = agent.get();
	const response = await axios.post(
		"/api/user/login",
		{ username: id, password }
	);
	return response.data.data;
}

async function me(access_token) {
	const axios = agent.get();
	const response = await axios.get(
		"/api/user/me",
		{ headers: { Authorization: "Bearer " + access_token } }
	);
	return response.data.data;
}

module.exports = {
	login, me
};
