const agent = require("./agent");

function marketPlatformIdList() {
	return agent
		.get()
        .get('/api/market-platform/allMarketPlaceIDs')
		.then((response) =>response.data);
}

module.exports = {
	marketPlatformIdList
};
