"use strict";
const agent = require("./agent");

module.exports = {

	listGroupPolicy() {
		return agent
			.get()
			.get("/api/permission/group-policy")
			.then(response => response.data.data);
	},
	getGroupPolicy(id) {
		return agent
			.get()
			.get("/api/permission/group-policy/" + id)
			.then(response => response.data.data);
	},
	updateGroupPolicy(id, selected) {
		return agent
			.get()
			.post("/api/permission/group-policy/" + id, { payload: selected })
			.then(response => response.data);
	},

	listUserGroup() {
		return agent
			.get()
			.get("/api/permission/user-group")
			.then(response => response.data.data);
	},
	getUserGroup(id) {
		return agent
			.get()
			.get("/api/permission/user-group/" + id)
			.then(response => response.data.data);
	},
	updateUserGroup(id, users) {
		return agent
			.get()
			.post("/api/permission/user-group/" + id, { payload: users })
			.then(response => response.data);
	}

};
