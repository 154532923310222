"use strict";
const agent = require("./agent");

async function listCost() {
	return agent
		.get()
		.get("/api/advertisement/cost")
		.then(response => response.data.data);
}

async function updateCost(payload) {
	return agent
		.get()
		.patch("/api/advertisement/cost", { data: payload })
		.then(response => response.data.data);
}

module.exports = {
	listCost,
	updateCost
};
