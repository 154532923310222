const agent = require("./agent");

async function listing() {
	return agent
		.get()
		.get("/api/dashboard/listing")
		.then((response) => response.data);
}

async function getListingLineChartData() {
	return agent
		.get()
		.get("/api/dashboard/listing/linechart")
		.then((response) => response.data);
}

module.exports = {
	listing,
	getListingLineChartData
};
