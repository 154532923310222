const agent = require("./agent");
const moment = require("moment");
const FileSaver = require("file-saver");

function addInlistingBlock(payload) {
	return agent
		.get()
        .post('/api/listing-block/add',{payload})
		.then((response) =>response.data);
}

function removeFromlistingBlock(payload) {
	return agent
		.get()
        .post('/api/listing-block/remove',{payload})
		.then((response) =>response.data);
}

function history(params) {
	return agent
		.get()
        .get('/api/listing-block/history',{ params })
		.then((response) =>response.data);
}

async function list(payload) {
	let buffer =await agent
		.get()
        .post('/api/listing-block/list',{ payload },{ responseType: "blob" })
		.then((response) =>response.data);
		const filename = `Inventory-Block-List-${moment().standard()}.xlsx`;
		return FileSaver.saveAs(buffer, filename);
}

module.exports = {
	addInlistingBlock,
	removeFromlistingBlock,
	history,
	list
};
