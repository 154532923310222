"use strict";
const agent = require("./agent");

function listProfiles() {
	return agent
		.get()
		.get("/api/shipping/profile")
		.then(response => response.data);
}

function estimate(payload) {
	return agent
		.get()
		.post("/api/shipping/estimate", payload)
		.then(response => response.data?.data || []);
}

function listTrackingReport(offset, limit) {
	return agent
		.get()
		.get("/api/shipping/tracking", { limit, offset })
		.then(response => response.data);
}

function requestTrackingReport(payload) {
	return agent
		.get()
		.post("/api/shipping/tracking", { payload })
		.then(response => response.data?.data);
}

module.exports = {
	estimate,
	profile: {
		list: listProfiles
	},
	tracking: {
		list: listTrackingReport,
		request: requestTrackingReport
	}
};
