"use strict";
const agent = require("./agent");

async function submit(payload) {
	const axios = agent.get();
	const response = await axios.post("/api/crawler/search-term", payload);
	return response.data;
};

async function list(payload) {
	const axios = agent.get();
	const response = await axios.get("/api/crawler/search-term", {
		params: payload
	});
	return response.data;
};

module.exports = {
	submit, list
};