const agent = require("./agent");

function list(queries) {
	return agent
		.get()
		.get("/api/listing/updates", { params: queries })
		.then((response) => response.data);
}

function update(ids) {
	return agent
		.get()
		.post("/api/listing/updates", { ids })
		.then((response) => response.data);
}

function getSummary() {
	return agent
		.get()
		.get("/api/listing/updates/summary")
		.then((response) => response.data.data);
}

function submitSummary(id) {
	return agent
		.get()
		.post("/api/listing/updates/summary", { id })
		.then((response) => response.data);
}

async function apiHistory(payload) {
	return agent
		.get()
		.get("/api/listing/updates/api-history", { params: payload })
		.then((response) => response.data);
}

module.exports = {
	list,
	update,
	getSummary,
	submitSummary,
	apiHistory
};
