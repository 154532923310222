const agent = require("./agent");

function getPendingImages(payload) {
	return agent
		.get()
		.get("/api/listing-image/pending-delete", { params: payload })
		.then((response) => response.data);
}

function submitPendingImages(body) {
	return agent
		.get()
		.post("/api/listing-image/pending-delete", { payload: body })
		.then((response) => response.data);
}

module.exports = {
	getPendingImages, submitPendingImages
};
