"use strict";
const agent = require("./agent");

async function list(queries) {
	return agent
		.get()
		.get("/api/listing/pre-launch", { params: queries })
		.then(response => response.data);
}

async function submit(profile, products) {
	return agent
		.get()
		.post("/api/listing/pre-launch", { payload: { profile, products } })
		.then(response => response.data);
}

module.exports = {
	list,
	submit
};
