"use strict";
const agent = require("./agent");

function list(payload) {
	return agent
		.get()
		.get("/api/review", { params: payload })
		.then(response => response.data);
}

function listByProduct(offset, limit) {
	return agent
		.get()
		.get("/api/product/review", { params: { offset, limit } })
		.then(response => response.data);
}

function updateProductReview(ids, remarks, tags) {
	return agent
		.get()
		.post("/api/product/review", { ids, remarks, tags })
		.then(response => response.data);
}

function listProductIssue(params) {
	return agent
		.get()
		.get("/api/product/review/issue", { params })
		.then(response => response.data);
}

function passReview(type, ids) {
	return agent
		.get()
		.patch("/api/product/review/issue", { type, ids })
		.then(response => response.data);
}

function getReviewReport(payload) {
	return agent
		.get()
		.get("/api/review/report", { params: payload })
		.then(response => response.data);
}


function deleteReviewReport(id) {
	return agent
		.get()
		.delete("/api/review/report/" + id)
		.then(response => response.data);
}

function requestReviewReport(payload) {
	return agent
		.get()
		.post("/api/review/report", { payload })
		.then(response => response.data);
}

module.exports = {
	list,
	listByProduct,
	updateProductReview,
	listProductIssue,
	passReview,
	getReviewReport,
	deleteReviewReport,
	requestReviewReport
};
