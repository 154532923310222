"use strict";
const agent = require("./agent");
const moment = require("moment");
const FileSaver = require("file-saver");

function listCurrent(payload) {
	return agent
		.get()
		.get("/api/inventory/reserved/current", { params: payload })
		.then(response => response.data);
}

function upload(id, name, size, type) {
	return agent
		.get()
		.post(
			"/api/inventory/reserved",
			{
				payload: {
					id,
					name,
					size,
					type
				}
			}
		)
		.then(response => response.data);
}

function list(payload) {
	return agent
		.get()
		.get("/api/inventory/reserved", { params: payload })
		.then(response => response.data);
}

function remove(id) {
	return agent
		.get()
		.delete("/api/inventory/reserved/" + id)
		.then(response => response.data);
}

async function exportFile() {
	const csv = await agent
		.get()
		.post("/api/inventory/reserved/export", {}, { responseType: "blob" })
		.then(response => response.data);
	const filename = `Inventory-Reserve-Qty-${moment().standard()}.xlsx`;
	return FileSaver.saveAs(csv, filename);
}

module.exports = {
	upload,
	list,
	remove,
	exportFile,
	listCurrent
};
