"use strict";
const moment = require("moment");
const FileSaver = require("file-saver");
const agent = require("./agent");

module.exports = {

	download: async function (queries) {
		const csv = await agent
			.get()
			.post(
				"/api/amazon/listing/export",
				queries,
				{ responseType: "blob" }
			)
			.then(response => response.data);
		const filename = `Amazon-v2-${moment().standard()}.xlsx`;
		return FileSaver.saveAs(csv, filename);
	},

	search: function (queries) {
		return agent
			.get()
			.post(
				"/api/amazon/listing/export/search",
				queries
			)
			.then(response => response.data);
	},

	listingUpdateReport: function (queries) {
		return agent
			.get()
			.get(
				"/api/amazon/listing/update-report", { params: queries },
			)
			.then(response => response.data);
	},

	exportReport: async function (queries) {
		const csv = await agent
			.get()
			.post(
				"/api/amazon/listing/export/update-report", 
				queries,
				{ responseType: "blob" }
			)
			.then(response => response.data);
		const filename = `Listing-update-report-${moment().standard()}.xlsx`;
		return FileSaver.saveAs(csv, filename);
	},
	
};
