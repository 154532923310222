module.exports = require("./agent");
module.exports.user = require("./user");
module.exports.price = require("./price");
module.exports.review = require("./review");
module.exports.option = require("./option");
module.exports.report = require("./report");

module.exports.listing = require("./listing");
module.exports.listingPrice = require("./listing.price");
module.exports.listingUpdates = require("./listing.updates");
module.exports.listingPreLaunch = require("./listing.pre-launch");
module.exports.listingRemove = require("./listing.remove");
module.exports.listingMismatch = require("./listing.mismatch");

module.exports.inventory = require("./inventory");
module.exports.inventoryReserve = require("./inventory.reserve");
module.exports.inventoryIgnore = require("./inventory.ignore");
module.exports.inventoryRecord = require("./inventory.record");

module.exports.crawler = require("./crawler");

module.exports.shipping = require("./shipping");
module.exports.dashboard = require("./dashboard");
module.exports.attachment = require("./attachment");
module.exports.channelAdvisor = require("./channel-advisor");
module.exports.advertisement = require("./advertisement");
module.exports.setting = require("./setting");
module.exports.categoryMap = require("./category-map");
module.exports.permission = require("./permission");

module.exports.amazon = require("./amazon");
module.exports.walmart = require("./walmart");
module.exports.walmartFS = require("./walmart-fs");

module.exports.product = require("./product");
module.exports.video = require("./video");

module.exports.expectedMargin = require("./setting.expected-margin");
module.exports.system = require("./system");

module.exports.listingImage = require("./listing.image");
module.exports.marketPlatform = require("./market-platform");
module.exports.listingBlock = require("./inventory.listing.block");
module.exports.inventoryAllocation = require("./inventory.allocation");


