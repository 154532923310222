"use strict";
const agent = require("./agent");
const moment = require("moment");
const FileSaver = require("file-saver");

module.exports = {
	inboundShipment: {
		list: (payload) => {
			return agent
				.get()
				.get("/api/walmart-fs/inbound-shipment", { params: payload })
				.then(response => response.data);
		},
		export: async (payload) => {
			const csv = await agent
				.get()
				.get(
					"/api/walmart-fs/inbound-shipment/export",
					{
						responseType: "blob",
						params: payload
					}
				)
				.then(response => response.data);
			const filename = `WalmartFS-Inbound-Shipment-${moment().format("YYYY-MM-DD")}.xlsx`;
			return FileSaver.saveAs(csv, filename);
		},
	},
	inboundShipmentItems: {
		list: (payload) => {
			return agent
				.get()
				.get("/api/walmart-fs/inbound-shipment-items", { params: payload })
				.then(response => response.data);
		},
		export: async (payload) => {
			const csv = await agent
				.get()
				.get(
					"/api/walmart-fs/inbound-shipment-items/export",
					{
						responseType: "blob",
						params: payload
					}
				)
				.then(response => response.data);
			const filename = `WalmartFS-Inbound-Shipment-Items-${moment().format("YYYY-MM-DD")}.xlsx`;
			return FileSaver.saveAs(csv, filename);
		},
	},
};
