const moment = require("moment");
const { parse } = require("json2csv");
const FileSaver = require("file-saver");
const agent = require("./agent");

function generate(payload) {
	return agent
		.get()
		.post("/api/inventory/record/generate", payload)
		.then((response) => response);
}

function getDownloadStatus(payload) {
	return agent
		.get()
		.get("/api/inventory/record/getDownloadStatus", payload)
		.then((response) => response);
}

module.exports = {
	generate,
	getDownloadStatus
};
