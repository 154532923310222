"use strict";
const agent = require("./agent");
const moment = require("moment");
const FileSaver = require("file-saver");

async function listMapPriceIssue(payload) {
	return agent
		.get()
		.get("/api/listing/price/map-price", { params: payload })
		.then(response => response.data.data);
}

async function submitMapPrice(ids) {
	return agent
		.get()
		.post("/api/listing/price/map-price", { payload: ids })
		.then(response => response.data.data);
}

async function upload(payload) {
	return agent
		.get()
		.post("/api/listing/price/upload", payload)
		.then(response => response.data.data);
}

async function getUploadProcess(payload) {
	return agent
		.get()
		.get("/api/listing/price/upload", { params: payload })
		.then(response => response.data.data);
}

async function exportMapPrice(payload) {
	const csv = await agent
		.get()
		.post("/api/listing/price/export", payload, { responseType: "blob" })
		.then(response => response.data);
	const filename = `Map-Price-${moment().standard()}.xlsx`;
	return FileSaver.saveAs(csv, filename);
}

async function pmCalculator(body) {
	return agent
		.get()
		.get("/api/listing/price/pm-calculator", body)
		.then(response => response.data.data);
}


module.exports = {
	listMapPriceIssue,
	submitMapPrice,
	upload,
	getUploadProcess,
	exportMapPrice,
	pmCalculator,
};
