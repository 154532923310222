"use strict";
const agent = require("./agent");

function get(type) {
	return agent
		.get()
		.get("/api/setting/" + type)
		.then(response => response.data.data);
}

function update(body) {
	return agent
		.get()
		.put("/api/setting", body)
		.then(response => response.data);
}

module.exports = {
	get,
	update
};
