"use strict";
const agent = require("./agent");

function get(ids) {
	return agent
		.get()
		.get("/api/option", { params: { ids } })
		.then(response => response.data.data);
}

function getAllowedMarketPlatform() {
	return agent
	.get()
	.get("/api/option/allowedMarketPlatform")
	.then(response => response.data.data);
}

module.exports = {
	get,
	getAllowedMarketPlatform
};
