const agent = require("./agent");

function get() {
	return agent
		.get()
		.get("/api/setting/expected-margin")
		.then((response) => response.data.data);
}

function updateExpectedMargin(payload) {
	return agent
		.get()
		.post("/api/setting/expected-margin", payload)
		.then((response) => response.data);
}

module.exports = {
	get,
	updateExpectedMargin
};
