"use strict";
const agent = require("./agent");

module.exports = {

	submit: async function (data) {
		const axios = agent.get();
		const response = await axios.post("/api/attachment", { data });
		return response.data;
	},

	requestUrl: async function (attachments) {
		const axios = agent.get();
		const response = await axios.post("/api/attachment/request-url", { data: attachments });
		return response.data.data;
	},

	remove: async function (id) {
		const axios = agent.get();
		const response = await axios.delete("/api/attachment/" + id);
		return response.data.data;
	},

	download: async function (id) {
	},
};
