"use strict";
const agent = require("./agent");
const FileSaver = require("file-saver");
const moment = require("moment");

async function unpublishedListings(queries) {
	return agent
		.get()
		.get("/api/walmart/listing-issues", { params: queries })
		.then(response => response.data);
}

async function listingQuality(queries) {
	return agent
		.get()
		.get("/api/walmart/listing-quality", { params: queries })
		.then(response => response.data);
}

async function listingQualityCount(body) {
	return agent
		.get()
		.patch("/api/walmart/listing-quality", body)
		.then(response => response.data);
}

async function dimensionIssue(queries) {
	return agent
		.get()
		.get("/api/walmart/dimension-issue", { params: queries })
		.then(response => response.data);
}

async function bulkUpdateWalmartDimensionIssue(ids, payload){
	return agent
		.get()
		.post("/api/walmart/dimension-issue", { ids, payload})
		.then(response => response.data)
}

async function exportDimensionIssue (queries) {
	const csv = await agent
		.get()
		.post(
			"/api/walmart/export-dimension-issue",
			queries,
			{ responseType: "blob" }
		)
		.then(response => response.data);
	const filename = `walmart-dimension-issue-${moment().standard()}.xlsx`;
	return FileSaver.saveAs(csv, filename);
}

function uploadOrder(payload) {
	return agent
		.get()
		.put("/api/walmart/order", payload)
		.then(response => response.data.data);
}

function listUploadOrderStatus(payload) {
	return agent
		.get()
		.get("/api/walmart/order", { params: payload })
		.then(response => response.data.data);
}

module.exports = {
	unpublishedListings,
	listingQuality,
	listingQualityCount,
	dimensionIssue,
	bulkUpdateWalmartDimensionIssue,
	exportDimensionIssue,
	uploadOrder,
	listUploadOrderStatus
};
