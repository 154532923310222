"use strict";
const agent = require("./agent");
const moment = require("moment");
const FileSaver = require("file-saver");

function list(payload) {

	return agent
		.get()
		.get("/api/listing/mismatch/title", { params: payload })
		.then(response => response.data);
}

function searchBrand(payload) {

	return agent
		.get()
		.get("/api/listing/mismatch/brand", { params: payload })
		.then(response => response.data);
}

function listAmazonContent(payload) {
	return agent
		.get()
		.get("/api/listing/mismatch/amazon-detail", { params: payload })
		.then(response => response.data);
}

async function exportAmazonContent(payload) {
	const csv = await agent
		.get()
		.get(
			"/api/listing/mismatch/amazon-detail/export",
			{
				responseType: "blob",
				params: payload
			}
		)
		.then(response => response.data);
	const filename = `Amazon Content Mismatch - ${moment().format("YYYY-MM-DD")}.xlsx`;
	return FileSaver.saveAs(csv, filename);

}

module.exports = {
	list,
	searchBrand,
	listAmazonContent,
	exportAmazonContent
};
